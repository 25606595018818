import React from 'react';
import PropTypes from 'prop-types';

import '../styles/pageLayout.css';

const Layout = ({ children, forwardedRef }) => {
  return (
    <div className="content" ref={forwardedRef}>
      {children}
    </div>
  );
};

Layout.defaultProps = {
  forwardedRef: null
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  forwardedRef: PropTypes.any
};

export default React.forwardRef((props, ref) => <Layout {...props} forwardedRef={ref} />);
